<template>
  <div
    class="container-top"
    ref="containerTop"
    :style="!isMobile ? { paddingBottom: '120px !important' } : {}"
    v-if="detail">
    <div class="pc">
      <div class="container padding-top-60">
        <div class="product-wrapper">
          <!-- 상품정보 좌 -->
          <div>
            <div class="card-img" :style="imgField(detail.img, 'pc')">
              <div class="card-exts" v-if="exts.length > 0">
                <img v-for="(ext, idx) in exts" :key="`ext-${idx}`" :src="`/static/icon/ext_${ext}.svg`" />
              </div>
              <div class="card-img-btns" style="gap: 8px">
                <a
                  v-if="detail.link || detail.preview_url"
                  :href="detail.link || detail.preview_url"
                  target="_blank"
                  class="flex-align button is-gray body2-bold sub"
                  style="gap: 6px">
                  <div>미리보기</div>
                  <svg-icon icon="u_arrow-up-right" color="black" :width="16" :height="16"></svg-icon>
                </a>
                <button class="flex-align button is-gray body2-bold sub" style="gap: 6px" @click="clickScreenshot">
                  <div>스크린샷</div>
                  <svg-icon icon="u_image" color="black" :width="16" :height="16"></svg-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- 상세정보 우 -->
          <div class="card-info-wrapper">
            <div class="card-info-header">
              <div class="flex-align" style="gap: 12px">
                <div class="body4 sub2 unselect" @click="clickCategor(detail.category2)">
                  {{ detail.category2_name }}
                </div>
                <template v-if="detail.category3 && detail.category3_name && detail.category3 !== 69">
                  <img src="/static/icon/u_bread-arrow.svg" class="svg-sub3" style="width: 5px" />
                  <div class="body4 sub2 unselect" @click="clickCategor(detail.category3)">
                    {{ detail.category3_name }}
                  </div>
                </template>
              </div>
              <div class="card-info-title">
                <div class="flex-align" style="gap: 4px">
                  <div class="subtitle3 main">{{ detail.title }}</div>
                  <div
                    v-if="detail.category3 && detail.category3 !== 69"
                    :class="`card-label ${detail.theme_version === 'beta' ? 'beta' : 'version'}`">
                    {{ detail.theme_version }}
                  </div>
                </div>
                <div class="body4 sub3">{{ detail.content }}</div>
                <div class="flex-align" style="gap: 16px">
                  <div class="flex-align" style="gap: 4px; cursor: pointer" @click="clickReview">
                    <img src="/static/icon/u_star-primary.svg" style="width: 14px" />
                    <div class="body5-medium main">
                      {{ detail.rate
                      }}<span class="sub3">({{ currencyNum(detail.reviews_count ? detail.reviews_count : 0) }})</span>
                    </div>
                  </div>
                  <div class="flex-align" style="gap: 4px">
                    <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width: 14px" />
                    <div class="body5-medium main">조회수 {{ detail.visit | currencyNum }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-right">
              <div class="h6 main">
                <template v-if="detail.price.price_type === 0">
                  <span v-if="detail.price.is_discount" class="primary" style="margin-right: 8px"
                    >{{ detail.price.discount_rate }}%</span
                  >
                  {{ priceFormat(detail.price) }}
                </template>
                <template v-else>
                  {{ detail.price.price_text }}
                </template>
              </div>
              <template v-if="detail.category3 && detail.category3 === 69">
                <div class="card-box-info">
                  <div class="card-box-info-item">
                    <div class="body4 sub3">작업범위</div>
                    <div class="body4-medium main">{{ detail.work_scope.join('˙') }}</div>
                  </div>
                  <div class="card-box-info-item">
                    <div class="body4 sub3">구축기간</div>
                    <div class="body4-medium main">{{ devPeriodFormat(detail) }}</div>
                  </div>
                  <div class="card-box-info-item">
                    <div class="body4 sub3">참여율</div>
                    <div class="body4-medium main">{{ detail.participation_rate }}%</div>
                  </div>
                  <div class="card-box-info-item">
                    <div class="body4 sub3">지원환경</div>
                    <div class="card-box-info-item-arr">
                      <div
                        class="body5 main card-box-info-item-arr-item"
                        v-for="(env, idx) in detail.support_env"
                        :key="`support-env-${idx}`">
                        {{ env }}
                      </div>
                    </div>
                  </div>
                  <div class="card-box-info-item">
                    <div class="body4 sub3">기술 스택</div>
                    <div class="card-box-info-item-arr">
                      <div
                        class="body5 main card-box-info-item-arr-item"
                        v-for="(tech, idx) in detail.techs"
                        :key="`tech-stack-${idx}`">
                        {{ tech }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-box-check">
                  <div class="card-box-check-item" v-for="(feature, idx) in detail.features" :key="`feature-${idx}`">
                    <img
                      src="/static/icon/u_check-primary.svg"
                      alt=""
                      class="unselect"
                      style="width: 20px; height: 20px" />
                    <div class="body4 sub">
                      {{ feature }}
                    </div>
                  </div>
                </div>
                <div class="line"></div>
                <!-- 제작문의 옵션 -->
                <!-- <div class="card-box-create-options">
                  <div class="body5-bold sub">제작문의 옵션</div>
                  <div
                    class="card-box-create-options-item"
                    v-for="(option, idx) in createOptions"
                    :key="`create-option-${idx}`"
                    :class="{ 'selected-option': optionRadio === option.id }"
                    @click="optionRadio = option.id">
                    <img
                      :src="`/static/icon/u_radio-${optionRadio === option.id ? 'on' : 'off'}.svg`"
                      alt=""
                      class="unselect"
                      style="width: 16px; height: 16px" />
                    <div class="flex-column" style="gap: 4px">
                      <div class="body4 sub">
                        {{ option.name }}
                      </div>
                      <div class="body4-bold main">
                        {{ option.id === 0 ? priceFormat(detail.price) : '비용문의' }}
                      </div>
                    </div>
                  </div>
                </div> -->
              </template>
              <!-- 미리보기 버튼 -->
              <!-- <div class="button-group-preview" v-if="detail.preview_url">
                <button class="button is-primary-light2" @click="showMobilePreview">
                  <img
                    src="/static/icon/u_mobile-android-alt.svg"
                    alt=""
                    class="unselect"
                    style="width:20px;height:20px"
                  />
                  <span class="body2-medium margin-left-12">모바일 미리보기</span>
                </button>
                <a :href="detail.preview_url" target="_blank">
                  <button class="button is-primary-light2">
                    <img src="/static/icon/fi_monitor.svg" alt="" class="unselect" style="width:20px;height:20px" />
                    <span class="body2-medium margin-left-12" style="letter-spacing: -0.5px">데스크톱 미리보기</span>
                  </button>
                </a>
              </div> -->
              <trial-button v-if="detail.category2 === 59" :product="detail"></trial-button>
              <skin-button v-else-if="detail.category2 === 60" :product="detail"></skin-button>
              <template-button
                v-else-if="detail.category2 === 61"
                :product="detail"
                :prodOp="optionRadio"></template-button>
            </div>
            <div class="partner-info-wrapper">
              <div class="partner-info-header unselect" @click="routerPush(`/partner_detail?id=${detail.partner.id}`)">
                <div class="body2-medium main text-left" style="flex: 1">{{ detail.partner.nickname }}</div>
                <div class="flex-align body5 sub2" style="gap: 8px">
                  <div>{{ businessTypeKor(detail.business_type) }}</div>
                  <div class="line-bar"></div>
                  <div>
                    템플릿 <span class="primary">{{ detail.partner.product_count | currencyNum }}개</span>
                  </div>
                </div>
              </div>
              <div class="partner-info-body">
                <div class="partner-info-body-item">
                  <img src="/static/icon/u_bag-gray.svg" alt="" class="unselect" style="width: 20px; height: 20px" />
                  <div class="flex-align" style="gap: 8px">
                    <div class="body4 sub2">경력</div>
                    <div class="subtitle7 main">
                      {{ detail.partner.career }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="detail.partner.operating_time && detail.partner.operating_time !== ''"
                  class="partner-info-body-item">
                  <img src="/static/icon/u_clock-gray.svg" alt="" class="unselect" style="width: 20px; height: 20px" />
                  <div class="flex-align" style="gap: 8px">
                    <div class="body4 sub2">연락가능 시간</div>
                    <div class="subtitle7 main">
                      {{ detail.partner.operating_time }}
                    </div>
                  </div>
                </div>
                <div class="partner-info-body-item">
                  <img
                    src="/static/icon/u_comment-gray.svg"
                    alt=""
                    class="unselect"
                    style="width: 20px; height: 20px" />
                  <div class="flex-align" style="gap: 8px">
                    <div class="body4 sub2">평균 응답 시간</div>
                    <div class="subtitle7 main">{{ detail.partner.response_time }} 이내</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="unselect flex-align bg-error50"
              style="border-radius: 12px; padding: 16px; gap: 6px"
              @click="$router.push('/partner_center')">
              <img src="/static/icon/fi_sale.svg" />
              <div class="subtitle6 primary">런치팩에게 제작의뢰를 받고 싶으신가요?</div>
            </div>
          </div>
        </div>
        <!-- 상품 상세 -->
        <!-- 관련 상품 -->
        <block-related-product
          v-if="detail.related_products.length > 0"
          :isTheme="true"
          :products="detail.related_products"></block-related-product>
        <!-- 탭 -->
        <tab-anchor
          v-if="!isMobile"
          ref="tab"
          :menu="dynamicTabs"
          :reviewCount="reviewCount"
          :inquiryCount="inquiryCount"
          :style="`margin-top:${detail.related_products.length > 0 ? '40' : '100'}px`">
          <template v-for="(tab, index) in dynamicTabs">
            <template :slot="`tab${index}`">
              <div class="content column is-12" :key="`pc-tab-${tab.name}-${index}`">
                <!-- 소개 탭 -->
                <template v-if="tab.name === '소개'">
                  <div v-if="detail.imgs && detail.imgs.length > 0" style="margin-top: 48px; padding: 0 75px">
                    <div class="content-slide" v-if="detail.imgs">
                      <slick ref="slick" :options="slickOptions">
                        <div v-for="(img, idx) in detail.imgs" :key="'slide-' + idx" class="slick-img-outer">
                          <img :src="img" alt="" class="slick-img" :style="slickImg" />
                        </div>
                      </slick>
                    </div>
                  </div>
                  <div
                    class="sun-editor-editable content column is-12"
                    id="anchor0"
                    style="padding: 0 !important"
                    v-html="detail.info">
                    {{ detail.info }}
                  </div>
                  <div class="tab-partner-info-container">
                    <div class="tab-partner-info-header">
                      <div class="h6 main">파트너 정보</div>
                    </div>
                    <div class="tab-partner-info-wrapper">
                      <div class="body2-medium main text-left" style="flex: 1">{{ detail.partner.nickname }}</div>
                      <div class="flex-align body5 sub2" style="gap: 8px">
                        <div>{{ businessTypeKor(detail.business_type) }}</div>
                        <div class="line-bar"></div>
                        <div>
                          템플릿 <span class="primary">{{ detail.partner.product_count | currencyNum }}개</span>
                        </div>
                      </div>
                    </div>
                    <div class="partner-info-body">
                      <div class="partner-info-body-item">
                        <img
                          src="/static/icon/u_bag-gray.svg"
                          alt=""
                          class="unselect"
                          style="width: 20px; height: 20px" />
                        <div class="flex-align" style="gap: 8px">
                          <div class="body4 sub2">경력</div>
                          <div class="subtitle7 main">
                            {{ detail.partner.career }}
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="detail.partner.operating_time && detail.partner.operating_time !== ''"
                        class="partner-info-body-item">
                        <img
                          src="/static/icon/u_clock-gray.svg"
                          alt=""
                          class="unselect"
                          style="width: 20px; height: 20px" />
                        <div class="flex-align" style="gap: 8px">
                          <div class="body4 sub2">연락가능 시간</div>
                          <div class="subtitle7 main">
                            {{ detail.partner.operating_time }}
                          </div>
                        </div>
                      </div>
                      <div class="partner-info-body-item">
                        <img
                          src="/static/icon/u_comment-gray.svg"
                          alt=""
                          class="unselect"
                          style="width: 20px; height: 20px" />
                        <div class="flex-align" style="gap: 8px">
                          <div class="body4 sub2">평균 응답 시간</div>
                          <div class="subtitle7 main">{{ detail.partner.response_time }} 이내</div>
                        </div>
                      </div>
                    </div>
                    <div class="partner-info-body-info body4 sub2" v-html="detail.partner.info"></div>
                    <button
                      class="button is-gray body2-bold main flex-align"
                      style="gap: 6px; width: 240px"
                      @click="routerPush(`/partner_detail?id=${detail.partner.id}`)">
                      <div>파트너 프로필 보기</div>
                      <img src="/static/icon/u_arrow-right.svg" />
                    </button>
                  </div>
                </template>

                <!-- FAQ 탭 -->
                <template v-else-if="tab.name === 'FAQ'">
                  <product-faq :faq="detail.faq"></product-faq>
                </template>

                <!-- 후기 탭 -->
                <template v-else-if="tab.name === '후기'">
                  <product-review
                    :productRate="detail.rate"
                    :isTemp="detail.category3 && detail.category3 === 69"
                    @updateReviewCount="updateReviewCount"></product-review>
                </template>

                <!-- 문의 탭 -->
                <template v-else-if="tab.name === '문의'">
                  <product-inquiry :product="detail" @updateInquiryCount="updateInquiryCount"></product-inquiry>
                </template>
              </div>
            </template>
          </template>
        </tab-anchor>
        <!-- 다른 상품 살펴보기 -->
        <div
          v-if="tempProducts.length > 0"
          class="margin-top-80"
          style="display: flex; flex-direction: column; gap: 16px; padding: 0 0.75rem 40px">
          <div class="h6 main">유사한 서비스 {{ serviceType }} 살펴보기</div>
          <div class="grid-4">
            <card-product-temp
              v-for="card in tempProducts"
              :key="`card-${card.id}`"
              :card="card"
              device="pc"
              @clickScreenshot="clickTempScreenshot"></card-product-temp>
          </div>
          <!-- <div v-else class="body5 sub3 text-center" style="padding: 80px 0">
            <template v-if="serviceType === '스킨'">
              유사한 서비스의 스킨이 없습니다.
            </template>
            <template v-else> 더 많은 {{ serviceType }}을 보시려면 파트너 프로필을 방문해주세요. </template>
          </div> -->
        </div>
      </div>
    </div>
    <div
      v-if="!vTop && !isMobile && [59, 60].includes(detail.category2)"
      class="bottom-btn-container"
      ref="bottomBtnContainer">
      <div class="bottom-btn-wrapper">
        <div>5분만에 플랫폼 개설! 무료로 먼저 체험해 보시고 결정하세요.</div>
        <button class="bottom-fixed-btn button is-primary body2-bold" @click="clickOrder">무료체험 시작하기</button>
      </div>
    </div>
    <div v-else-if="vTop && !isMobile" style="height: 84px" ref="bottomBtnContainer"></div>
    <div class="mobile">
      <div class="card-img" :style="imgField(detail.img, 'mobile')">
        <div class="card-exts" v-if="exts.length > 0">
          <img v-for="(ext, idx) in exts.slice(0, 5)" :key="`ext-${idx}`" :src="`/static/icon/ext_${ext}.svg`" />
        </div>
        <div class="card-img-btns" style="gap: 8px">
          <a
            :href="detail.link"
            target="_blank"
            class="flex-align button is-gray body4-medium sub"
            style="gap: 6px; height: 44px">
            <div>미리보기</div>
            <svg-icon icon="u_arrow-up-right" color="black" :width="20" :height="20"></svg-icon>
          </a>
          <button
            class="flex-align button is-gray body4-medium sub"
            style="gap: 6px; height: 44px"
            @click="clickScreenshot">
            <div>스크린샷</div>
            <svg-icon icon="u_image" color="black" :width="20" :height="20"></svg-icon>
          </button>
        </div>
      </div>
      <div
        class="container padding-top-16"
        :class="detail.category3 && detail.category3 === 69 ? '' : 'padding-bottom-24'">
        <!-- 파트너 -->
        <div class="flex-align" style="gap: 12px">
          <div class="body5 sub2 unselect" @click="clickCategor(detail.category2)">
            {{ detail.category2_name }}
          </div>
          <template v-if="detail.category3 && detail.category3_name && detail.category3 !== 69">
            <img src="/static/icon/u_bread-arrow.svg" class="svg-sub3" style="width: 5px" />
            <div class="body5 sub2 unselect" @click="clickCategor(detail.category3)">
              {{ detail.category3_name }}
            </div>
          </template>
        </div>
        <div class="flex-align" style="gap: 4px">
          <div class="h7 main" style="margin-top: 6px">{{ detail.title }}</div>
          <div
            v-if="detail.category3 && detail.category3 !== 69"
            :class="`card-label ${detail.theme_version === 'beta' ? 'beta' : 'version'}`"
            style="margin-top: 6px">
            {{ detail.theme_version }}
          </div>
        </div>
        <div class="body5 sub2" style="padding-top: 6px">{{ detail.content }}</div>
        <div class="body5-medium sub2" style="padding: 8px 0">{{ detail.partner.name }}</div>
        <div class="flex-align" style="gap: 16px; margin-top: 6px">
          <div class="flex-align" style="gap: 4px; cursor: pointer" @click="clickReview">
            <img src="/static/icon/u_star-primary.svg" style="width: 16px" />
            <div class="body5-medium main">
              {{ detail.rate
              }}<span class="main">({{ currencyNum(detail.reviews_count ? detail.reviews_count : 0) }})</span>
            </div>
          </div>
          <div class="flex-align" style="gap: 4px">
            <img src="/static/icon/u_eye.svg" class="svg-sub3" style="width: 16px" />
            <div class="body5-medium main">조회수 {{ detail.visit | currencyNum }}</div>
          </div>
        </div>
        <div class="body0-bold main margin-top-16">
          <template v-if="detail.price.price_type === 0">
            <span v-if="detail.price.is_discount" class="primary" style="margin-right: 4px"
              >{{ detail.price.discount_rate }}%</span
            >
            {{ priceFormat(detail.price) }}
          </template>
          <template v-else>
            {{ detail.price.price_text }}
          </template>
        </div>

        <!-- <a class="mobile-preview" :href="detail.preview_url" target="_blank" v-if="detail.preview_url">
          <button class="button is-primary-light2 margin-top-24 body4" style="width:100%;height:40px">미리보기</button>
        </a> -->
        <trial-button v-if="detail.category2 === 59" :product="detail"></trial-button>
        <skin-button v-else-if="detail.category2 === 60" :product="detail"></skin-button>
        <template-button v-else-if="detail.category2 === 61" :product="detail"></template-button>
      </div>
      <template v-if="detail.category3 && detail.category3 === 69">
        <div class="lp-divider-gray3" style="margin: 16px"></div>
        <div class="flex-column" style="gap: 16px; padding: 0 16px 16px">
          <div class="card-box-info">
            <div class="card-box-info-item">
              <div class="body4 sub3">작업범위</div>
              <div class="body4-medium main">{{ detail.work_scope.join('˙') }}</div>
            </div>
            <div class="card-box-info-item">
              <div class="body4 sub3">구축기간</div>
              <div class="body4-medium main">{{ devPeriodFormat(detail) }}</div>
            </div>
            <div class="card-box-info-item">
              <div class="body4 sub3">참여율</div>
              <div class="body4-medium main">{{ detail.participation_rate }}%</div>
            </div>
            <div class="card-box-info-item">
              <div class="body4 sub3">지원환경</div>
              <div class="card-box-info-item-arr">
                <div
                  class="body5 main card-box-info-item-arr-item"
                  v-for="(env, idx) in detail.support_env"
                  :key="`support-env-${idx}`">
                  {{ env }}
                </div>
              </div>
            </div>
            <div class="card-box-info-item">
              <div class="body4 sub3">기술 스택</div>
              <div class="card-box-info-item-arr">
                <div
                  class="body5 main card-box-info-item-arr-item"
                  v-for="(tech, idx) in detail.techs"
                  :key="`tech-stack-${idx}`">
                  {{ tech }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-box-check">
            <div class="card-box-check-item" v-for="(feature, idx) in detail.features" :key="`feature-${idx}`">
              <img src="/static/icon/u_check-primary.svg" alt="" class="unselect" style="width: 20px; height: 20px" />
              <div class="body4 sub">
                {{ feature }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="line"></div>
      <div class="parnter-info-container">
        <div class="partner-info-wrapper">
          <div class="partner-info-header unselect" @click="routerPush(`/partner_detail?id=${detail.partner.id}`)">
            <div class="body2-medium main text-left" style="flex: 1">{{ detail.partner.name }}</div>
            <div class="flex-align body5 sub2" style="gap: 8px">
              <div>{{ businessTypeKor(detail.business_type) }}</div>
              <div class="line-bar"></div>
              <div>
                템플릿 <span class="primary">{{ detail.partner.product_count | currencyNum }}개</span>
              </div>
            </div>
          </div>
          <div class="partner-info-body">
            <div class="partner-info-body-item">
              <img src="/static/icon/u_bag-gray.svg" alt="" class="unselect" style="width: 20px; height: 20px" />
              <div class="flex-align" style="gap: 8px">
                <div class="body4 sub2">경력</div>
                <div class="subtitle7 main">
                  {{ detail.partner.career }}
                </div>
              </div>
            </div>
            <div
              v-if="detail.partner.operating_time && detail.partner.operating_time !== ''"
              class="partner-info-body-item">
              <img src="/static/icon/u_clock-gray.svg" alt="" class="unselect" style="width: 20px; height: 20px" />
              <div class="flex-align" style="gap: 8px">
                <div class="body4 sub2">연락가능 시간</div>
                <div class="subtitle7 main">
                  {{ detail.partner.operating_time }}
                </div>
              </div>
            </div>
            <div class="partner-info-body-item">
              <img src="/static/icon/u_comment-gray.svg" alt="" class="unselect" style="width: 20px; height: 20px" />
              <div class="flex-align" style="gap: 8px">
                <div class="body4 sub2">평균 응답 시간</div>
                <div class="subtitle7 main">{{ detail.partner.response_time }} 이내</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="unselect flex-align bg-error50"
          style="border-radius: 12px; padding: 16px; gap: 6px"
          @click="$router.push('/partner_center')">
          <img src="/static/icon/fi_sale.svg" />
          <div class="subtitle6 primary">런치팩에게 제작의뢰를 받고 싶으신가요?</div>
        </div>
      </div>

      <!-- 관련 상품 -->
      <block-related-product
        v-if="detail.related_products.length > 0"
        :isTheme="true"
        :products="detail.related_products"></block-related-product>
      <!-- 상품 상세-->
      <div>
        <div class="lp-divider-gray3-12 margin-top-12"></div>
        <!-- 탭 -->
        <tab-anchor
          v-if="isMobile"
          ref="mtab"
          :menu="dynamicTabs"
          :reviewCount="reviewCount"
          :inquiryCount="inquiryCount"
          anchor="mtabs">
          <template v-for="(tab, index) in dynamicTabs">
            <template :slot="`tab-mobile-${index}`">
              <div :key="`mobile-tab-${tab.name}-${index}`">
                <!-- 소개 탭 -->
                <template v-if="tab.name === '소개'">
                  <div v-if="detail.imgs && detail.imgs.length > 0" class="content" style="padding: 0 16px">
                    <div class="content-slide" v-if="detail.imgs">
                      <scroll-wrapper>
                        <div class="flex-align">
                          <template v-for="(img, idx) in detail.imgs">
                            <img :src="img" alt="" class="slick-img" :style="slickImg" :key="'slide-' + idx" />
                          </template>
                        </div>
                      </scroll-wrapper>
                    </div>
                  </div>
                  <div
                    class="sun-editor-editable content column is-12"
                    id="anchor0"
                    style="padding: 0 16px !important"
                    v-html="detail.info"></div>
                  <div class="tab-partner-info-container">
                    <div class="tab-partner-info-header">
                      <div class="h6 main">파트너 정보</div>
                    </div>
                    <div class="tab-partner-info-wrapper">
                      <div class="body2-medium main text-left" style="flex: 1">{{ detail.partner.nickname }}</div>
                      <div class="flex-align body5 sub2" style="gap: 8px">
                        <div>{{ businessTypeKor(detail.business_type) }}</div>
                        <div class="line-bar"></div>
                        <div>
                          템플릿 <span class="primary">{{ detail.partner.product_count | currencyNum }}개</span>
                        </div>
                      </div>
                    </div>
                    <div class="partner-info-body">
                      <div class="partner-info-body-item">
                        <img
                          src="/static/icon/u_bag-gray.svg"
                          alt=""
                          class="unselect"
                          style="width: 20px; height: 20px" />
                        <div class="flex-align" style="gap: 8px">
                          <div class="body4 sub2">경력</div>
                          <div class="subtitle7 main">
                            {{ detail.partner.career }}
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="detail.partner.operating_time && detail.partner.operating_time !== ''"
                        class="partner-info-body-item">
                        <img
                          src="/static/icon/u_clock-gray.svg"
                          alt=""
                          class="unselect"
                          style="width: 20px; height: 20px" />
                        <div class="flex-align" style="gap: 8px">
                          <div class="body4 sub2">연락가능 시간</div>
                          <div class="subtitle7 main">
                            {{ detail.partner.operating_time }}
                          </div>
                        </div>
                      </div>
                      <div class="partner-info-body-item">
                        <img
                          src="/static/icon/u_comment-gray.svg"
                          alt=""
                          class="unselect"
                          style="width: 20px; height: 20px" />
                        <div class="flex-align" style="gap: 8px">
                          <div class="body4 sub2">평균 응답 시간</div>
                          <div class="subtitle7 main">{{ detail.partner.response_time }} 이내</div>
                        </div>
                      </div>
                    </div>
                    <div class="partner-info-body-info body4 sub2" v-html="detail.partner.info"></div>
                    <button
                      class="button is-gray body2-bold main flex-align"
                      style="gap: 6px; width: 240px; height: 52px"
                      @click="routerPush(`/partner_detail?id=${detail.partner.id}`)">
                      <div>파트너 프로필 보기</div>
                      <img src="/static/icon/u_arrow-right.svg" />
                    </button>
                  </div>
                </template>

                <!-- FAQ 탭 -->
                <template v-else-if="tab.name === 'FAQ'">
                  <product-faq :faq="detail.faq"></product-faq>
                </template>

                <!-- 후기 탭 -->
                <template v-else-if="tab.name === '후기'">
                  <product-review
                    :productRate="detail.rate"
                    :isTemp="detail.category3 && detail.category3 === 69"
                    @updateReviewCount="updateReviewCount"></product-review>
                </template>

                <!-- 문의 탭 -->
                <template v-else-if="tab.name === '문의'">
                  <product-inquiry :product="detail" @updateInquiryCount="updateInquiryCount"></product-inquiry>
                </template>
              </div>
            </template>
          </template>
        </tab-anchor>
        <!-- 다른 상품 살펴보기 -->
        <div v-if="tempProducts.length > 0" class="themes-wrapper-mobile">
          <div class="h8 main">유사한 서비스 {{ serviceType }} 살펴보기</div>
          <div id="container-1" class="flex-column" style="gap: 16px; margin-top: 16px">
            <card-product-temp
              v-for="card in tempProducts"
              :key="`card-${card.id}`"
              :card="card"
              device="mobile"></card-product-temp>
          </div>
          <!-- <div v-else class="body5 sub3 text-center" style="padding: 80px 0">
            <template v-if="serviceType === '스킨'">
              유사한 서비스의 스킨이 없습니다.
            </template>
            <template v-else> 더 많은 {{ serviceType }}을 보시려면 파트너 프로필을 방문해주세요. </template>
          </div> -->
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-show="vTop && !isMobile && [59, 60].includes(detail.category2)" class="fixed-bottom-btn-container">
        <div class="fixed-bottom-btn-wrapper">
          <div>5분만에 플랫폼 개설! 무료로 먼저 체험해 보시고 결정하세요.</div>
          <button class="bottom-fixed-btn button is-primary body2-bold" @click="clickOrder">무료체험 시작하기</button>
        </div>
      </div>
    </transition>
    <screenshot-modal
      ref="screenshotModal"
      :detail="seletedScreenshot"
      @updateBookmark="updateBookmark"
      @clickProdInquiry="clickProdInquiry"></screenshot-modal>
  </div>
</template>

<script>
import UserAPIMixin from '../../mixins/UserAPIMixin';
import ProductInquiry from './ProductInquiry';
import ProductFaq from './ProductFaq';
import ProductReview from './ProductReview';
import TabAnchor from '../module/TabAnchor';
import ScrollWrapper from '../module/ScrollWrapper';
import TrialButton from '../component/TrialButton';
import BlockRelatedProduct from '../component/BlockRelatedProduct';
import Slick from 'vue-slick';
import TemplateButton from '../component/TemplateButton';
import SkinButton from '../component/SkinButton';
import SvgIcon from '../component/SvgIcon';
import ScreenshotModal from '../modal/ScreenshotModal';
import CardProductTemp from '../component/CardProductTemp';

export default {
  name: 'TemplateDetail',
  mixins: [UserAPIMixin],
  components: {
    SkinButton,
    TemplateButton,
    ProductReview,
    BlockRelatedProduct,
    TrialButton,
    ScrollWrapper,
    TabAnchor,
    ProductInquiry,
    Slick,
    CardProductTemp,
    SvgIcon,
    ScreenshotModal,
    ProductFaq,
  },
  data() {
    return {
      themes: [],
      skins: [],
      tempProducts: [],
      vTop: false,
      order_product: undefined,
      tabs: [
        { id: 0, name: '소개' },
        { id: 1, name: 'FAQ' },
        { id: 2, name: '후기' },
        { id: 3, name: '문의' },
      ],
      // 상세 상품
      detail: undefined,
      slickOptions: {
        centerMode: false,
        variableWidth: false,
        infinite: true,
        autoplay: false,
        centerPadding: '20px',
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 600,
        prevArrow:
          '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="prev"></div>',
        nextArrow:
          '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="next"></div>',
        responsive: [
          {
            breakpoint: 767,
            settings: {
              autoplay: true,
              slidesToShow: 1,
              centerMode: true,
              dots: false,
              arrows: false,
            },
          },
        ],
      },
      optionRadio: 1,
      createOptions: [
        { id: 0, name: '템플릿 그대로 제작' },
        { id: 1, name: '템플릿을 바탕으로 수정 제작' },
      ],
      seletedScreenshot: undefined,
      reviewCount: 0,
      inquiryCount: 0,
    };
  },
  watch: {
    $route(n) {
      if (n.query.id) {
        this.getProductDetail();
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getProductDetail();

      if (this.isMobile) {
        this.slickOptions.variableWidth = false;
        this.slickOptions.centerMode = false;
        this.slickOptions.autoplay = true;
      }
    }
    this.init();
  },
  beforeDestroy() {
    //document.getElementById('ch-plugin').style.display = '';
    window.removeEventListener('scroll', this.updateVTop);
  },
  mounted() {
    // setTimeout(() => {
    //   document.getElementById('ch-plugin').style.display = 'none';
    // }, 1000);
    this.updateVTop(); // 초기 스크롤 위치에 따라 vTop 설정
    window.addEventListener('scroll', this.updateVTop);
  },
  computed: {
    serviceType() {
      const { category2, category3 } = this.detail;
      const typeMap = {
        60: '스킨',
        69: '플랫폼',
        70: '소스코드',
      };

      return typeMap[category2] || typeMap[category3] || '';
    },
    exts() {
      return this.detail && this.detail.params
        ? this.detail.params.find((i) => i.name === '파일 종류')
          ? this.detail.params
              .find((i) => i.name === '파일 종류')
              .choice_items.filter(
                (i) => this.detail.params.find((i) => i.name === '파일 종류').value_select.indexOf(i.id) > -1
              )
              .map((i) => i.name.toLowerCase())
          : []
        : [];
    },
    category_name() {
      if (this.detail) {
        if (this.detail.category2_name.indexOf('테마') > -1) {
          return '테마';
        } else if (this.detail.category2_name.indexOf('스킨') > -1) {
          return '스킨';
        } else {
          return '템플릿';
        }
      } else {
        return '';
      }
    },
    slickImg() {
      if (window.innerWidth > 500) {
        let width = 318;
        let height = 483;
        let margin = '0 6px';
        return {
          width: width + 'px',
          height: height + 'px',
          margin: margin,
          borderRadius: '8px',
          backgroundColor: '#FAFAFA',
          objectFit: 'contain',
        };
      } else {
        let width = window.innerWidth - 24.5;
        let height = width * 1.31;
        let margin = '0 6px';
        return {
          width: width + 'px',
          height: height + 'px',
          margin: margin,
          // borderRadius: '8px',
          backgroundColor: '#FAFAFA',
          objectFit: 'contain',
        };
      }
    },
    contentSlideStyle() {
      if (window.innerWidth < 500) {
        return {
          width: '100%',
          height: this.slickImg.height,
        };
      } else {
        return {
          width: '1106px',
          height: this.slickImg.height,
        };
      }
    },
    dynamicTabs() {
      const baseTabs = [
        { id: 0, name: '소개' },
        { id: 1, name: '후기' },
        { id: 2, name: '문의' },
      ];

      if (this.detail && this.detail.faq && this.detail.faq.length > 0) {
        return [
          { id: 0, name: '소개' },
          { id: 1, name: 'FAQ' },
          { id: 2, name: '후기' },
          { id: 3, name: '문의' },
        ];
      }

      return baseTabs;
    },
  },
  methods: {
    updateReviewCount(count) {
      this.reviewCount = count;
    },
    updateInquiryCount(count) {
      this.inquiryCount = count;
    },
    updateBookmark(isBookmarked) {
      console.log('updateBookmark', isBookmarked);
      this.detail.is_liked = isBookmarked;
    },
    clickProdInquiry() {
      if (!this.isLogin) {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.toast('로그인 후 진행해주세요.');
        this.$router.push('/signin');
        return;
      }
      let prodInquiry = {
        product: this.detail,
        option: this.optionRadio,
      };
      if (this.$store.getters.productInquiry) {
        this.$store.commit('setProductInquiry', null);
      }
      this.$store.commit('setProductInquiry', prodInquiry);
      this.$nextTick(() => {
        this.routerPush('/template_prod_inquiry');
      });
    },
    clickScreenshot() {
      this.seletedScreenshot = this.detail;
      this.$nextTick(() => {
        this.$refs.screenshotModal.open();
      });
    },
    clickTempScreenshot(card) {
      this.seletedScreenshot = card;
      this.$nextTick(() => {
        this.$refs.screenshotModal.open();
      });
    },
    devPeriodFormat(detail) {
      if (!detail || !detail.dev_start || !detail.dev_end) {
        return '';
      }

      const start = this.dayjs(detail.dev_start);
      const end = this.dayjs(detail.dev_end);
      const monthsDiff = end.diff(start, 'month');

      const formattedStart = this.datesFormat(detail.dev_start, 'date_9');
      const formattedEnd = this.datesFormat(detail.dev_end, 'date_9');

      return `${monthsDiff}개월 (${formattedStart} ~ ${formattedEnd})`;
    },
    priceFormat(price) {
      let result = this.currency(price.sale_price);
      if (price.price_type === 0) {
        if (price.won_type === 1) {
          result += '~';
        } else if (price.won_type === 2) {
          result += ' 초반대';
        } else if (price.won_type === 3) {
          result += ' 후반대';
        }
      } else {
        result = price.price_text;
      }
      return result;
    },
    updateVTop() {
      const containerTop = this.$refs.containerTop;
      const bottomBtnContainer = this.$refs.bottomBtnContainer; // 새로운 ref 추가 필요
      if (containerTop && bottomBtnContainer) {
        // const containerTopHeight = containerTop.offsetHeight;
        const bottomBtnPosition = bottomBtnContainer.offsetTop;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;

        // 스크롤 위치가 bottomBtnContainer의 상단에 도달하면 vTop을 false로 설정
        if (scrollTop + windowHeight >= bottomBtnPosition) {
          this.vTop = false;
        } else if (scrollTop <= 300) {
          this.vTop = false;
        } else {
          this.vTop = true;
        }
      }
    },
    clickCategor(id) {
      this.routerPush(`/template_category?id=${id}`);
    },
    // clickCategory() {
    //   let id = {
    //     59: 1,
    //     60: 2,
    //     61: 3,
    //   }[this.detail.category2];
    //   this.routerPush(`/theme_market?tab=${id}`);
    // },
    clickReview() {
      const reviewTabId = this.dynamicTabs.find((tab) => tab.name === '후기')?.id;
      if (reviewTabId) {
        if (this.isMobile) {
          this.$refs.mtab.handleTabClick(reviewTabId);
        } else {
          this.$refs.tab.handleTabClick(reviewTabId);
        }
      }
    },
    init() {
      this.order_product = {
        product_id: this.$route.query.id,
        quantity: 1,
        discount_price: 0,
        product_price: 0,
        total_price: 0,
        product: this.cloneItem(this.detail),
        order_product_option: [],
        required_option_price: 0,
        date_unit: 0,
      };
    },
    clickOrder() {
      this.$store.commit('setBasket', this.order_product);
      if (this.isLogin) {
        //this.routerPush('/trial_account');
        this.routerPush('/service_survey');
      } else {
        this.$store.commit('setPrevPath', this.$route.fullPath);
        this.$store.commit('setTrial', true);
        this.toast('로그인 후 진행 할 수 있습니다.');
        this.$router.replace('/signup');
      }
    },
    showMobilePreview() {
      window.name = 'preview_parent';
      let popup = window.open(
        '',
        'previewPopup',
        'width=360, height=640, top=300, left=300, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no'
      );
      popup.location.href = this.detail.preview_url;
    },
    preview(device) {
      console.log(device);
      location.href = this.detail.preview_url;
    },
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
    getThemeProducts() {
      let params = {
        category: 59,
        fields:
          'id,name,simple_desc,price,img,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
        page_num: 1,
        page_length: 5,
        ordering: 'priority,-id',
        price_start: this.detail.price.sale_price - 10000000,
        price_end: this.detail.price.sale_price + 10000000,
      };
      let user_id = this.$store.state.user.user_id;
      if (this.serviceType === '스킨') {
        // skins
        params.category = 60;
        this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
          this.tempProducts = res.data.data.filter((i) => i.id !== Number(this.$route.query.id));
        });
      } else if (this.serviceType === '템플릿') {
        // template
        params.category = 58;
        this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
          this.tempProducts = res.data.data.filter((i) => i.id !== Number(this.$route.query.id));
        });
      } else {
        // source code
        params.category = 70;
        this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
          this.tempProducts = res.data.data.filter((i) => i.id !== Number(this.$route.query.id));
        });
      }
    },
    // 상품 상세
    getProductDetail() {
      let request = this.request.user;
      let url = `launchpack/v1/theme/${this.$route.query.id}`;
      if (!this.isLogin) {
        request = this.$axios;
        url = 'public/' + url;
      }
      this.$store.commit('setLoading', true);
      request
        .get(url)
        .then(async (res) => {
          if (res.status === 200) {
            this.$store.commit('setLoading', false);
            if (res.data.preview_url && res.data.preview_url.indexOf('demo') === -1) {
              let key = res.data.preview_url.split('.')[0].replace('https://', '');
              res.data.preview_url += `/home?demo_test=${key}`;
            }

            //res.data.img = res.data.imgs[0];
            // 대표이미지 제외
            //res.data.imgs = res.data.imgs.splice(1);
            // 슬라이드쇼 반복을 위해서 concat
            // res.data.imgs = res.data.imgs.concat(res.data.imgs);
            let detail = res.data;
            if (!detail.service_use_price) {
              detail.service_use_price = {
                id: 1,
                name: '서비스 운영 이용료',
                desc: null,
                price: 300000,
              };
            }
            await this.setServiceUsePrice(detail);
            this.detail = detail;
            if (!detail.faq) {
              this.tabs = [
                { id: 0, name: '소개' },
                { id: 1, name: '후기' },
                { id: 2, name: '문의' },
              ];
            }
            this.getThemeProducts();
          }
        })
        .catch(() => {});
    },
    async setServiceUsePrice(detail) {
      let pack_name = '';
      let item = this.findItem(detail.params, 'name', '주문전환');
      if (item) {
        if (item.value_select && item.value_select.length > 0) {
          let pack_item = this.findItem(item.choice_items, 'id', item.value_select[0]);
          if (pack_item) {
            pack_name = pack_item.name;
          }
        }
        let query = '';
        if (pack_name) {
          query = `?pack=${this.convertPack(pack_name)}`;
        }
        await this.$axios.get(`public/launchpack/service_use_price/info${query}`).then((res) => {
          detail.service_use_price = res.data;
        });
      }
    },
    convertPack(name) {
      switch (name) {
        case '':
          return '';
        case '전화연결':
          return 'call';
        case '아웃링크':
          return 'outlink';
        case '상품배송주문':
          return 'delivery';
        case '모임신청':
          return 'meet';
        case '상담신청':
          return 'counsel';
        case '견적청':
          return 'estimate';
        case '사용권':
          return 'license';
        case '컨텐��다운로드주문':
          return 'commerce';
        case '숙박예약':
          return 'booking';
        case '서비스예약':
          return 'reservation';
      }
    },
    businessTypeKor(type) {
      switch (type) {
        case 0:
          return '법인사업자';
        case 1:
          return '개인사업자';
        case 2:
          return '팀';
        case 3:
          return '개인';
      }
    },
    imgField(img, device) {
      let style = {
        backgroundImage: `url(${this.image(img)})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
      let [w, h] = this.imgRate(device === 'pc' ? 660 : 375);
      style.height = `${h}px`;
      style.width = device === 'pc' ? `${w}px` : '100%';
      style.objectFit = 'cover';

      style.borderRadius = device === 'pc' ? '19.556px' : '0';
      // if (device === 'mobile') {
      //   style.height = ((window.innerWidth * 3) / 4).toString() + 'px';
      // }
      return style;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.pc
  .product-wrapper
    display grid
    grid-gap 0 72px
    grid-template-columns repeat(2, 1fr)
  .card-info-wrapper
    display flex
    flex-direction column
    gap 16px
  .card-info-header
    display flex
    flex-direction column
    gap 16px
    .card-info-title
      display flex
      flex-direction column
      gap 4px
  .card-box-info
    display flex
    flex-direction column
    gap 8px
    .card-box-info-item
      display grid
      grid-template-columns 72px 1fr
      align-items start
      gap 8px
      .card-box-info-item-arr
        display flex
        align-items center
        flex-wrap wrap
        gap 8px
        .card-box-info-item-arr-item
          padding 2px 6px
          background-color $gray2
          border-radius 4px
          display flex
          align-items center
          justify-content center
  .partner-info-wrapper
    display flex
    flex-direction column
    border-radius 12px
    border 1px solid $gray1
    .partner-info-header
      display flex
      flex-direction column
      background-color $gray3
      padding 20px 24px
      border-radius 12px 12px 0 0
    .partner-info-body
      display flex
      flex-direction column
      gap 8px
      padding 24px
      border-radius 0 12px
      .partner-info-body-item
        display grid
        grid-template-columns 20px 1fr
        gap 8px
        align-items center

  .card-box-check
    display flex
    flex-direction column
    gap 4px
    .card-box-check-item
      display flex
      align-items center
      gap 4px
  .card-box-create-options
    display flex
    flex-direction column
    gap 12px
    .card-box-create-options-item
      display grid
      grid-template-columns 16px 1fr
      align-items start
      padding 12px 16px
      gap 8px
      border-radius 8px
      border 1px solid $gray1
      cursor pointer
      &.selected-option
        border 2px solid $primary
        padding 11px 15px

  .card-img
    position relative
    width 516px
    height 387px
    border-radius 8px
    .card-exts
      position absolute
      border-radius 6px
      top 8px
      right 8px
      z-index 1
      padding 7px 10px
      background-color $main
      display flex
      align-items center
      gap 8px
      img
        width 24px
        height 24px
    .card-img-btns
      position absolute
      bottom 0
      left 0
      right 0
      padding 48px 24px 24px
      width 660px
      background linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)
      border-radius 0 0 19.556px 19.556px
      display grid
      grid-template-columns repeat(2, 1fr)
      gap 10px

  .tab-partner-info-container
    display flex
    flex-direction column
    gap 24px
    padding 48px 0
    .tab-partner-info-wrapper
      display flex
      flex-direction column
    .partner-info-body
      display flex
      flex-direction column
      gap 8px
      .partner-info-body-item
        display grid
        grid-template-columns 20px 1fr
        gap 8px
        align-items center
    .partner-info-body-info
      white-space pre-line

  .box-right
    border 1px solid $gray2
    padding 24px 32px
    border-radius 12px
    display flex
    flex-direction column
    gap 16px

  .img-partner
    width 40px
    height 40px
    border-radius 100%
  .grid-4
    display grid
    grid-template-columns repeat(4, 1fr)
    grid-gap 36px

.mobile
  .card-img
    position relative
    width 100%
    border 1px solid $gray3
    .card-exts
      position absolute
      border-radius 6px
      top 4px
      right 4px
      z-index 1
      padding 7px 10px
      background-color $main
      display flex
      align-items center
      gap 12px
      img
        width 24px
        height 24px
    .card-img-btns
      position absolute
      bottom 0
      left 0
      right 0
      padding 24px 16px 16px
      width 100%
      background linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)
      display grid
      grid-template-columns repeat(2, 1fr)
      gap 10px
  .card-box-info
    display flex
    flex-direction column
    gap 8px
    .card-box-info-item
      display grid
      grid-template-columns 72px 1fr
      align-items start
      gap 8px
      .card-box-info-item-arr
        display flex
        align-items center
        flex-wrap wrap
        gap 8px
        .card-box-info-item-arr-item
          padding 2px 6px
          background-color $gray2
          border-radius 4px
          display flex
          align-items center
          justify-content center
  .card-box-check
    display flex
    flex-direction column
    gap 4px
    .card-box-check-item
      display flex
      align-items center
      gap 4px
  .parnter-info-container
    display flex
    flex-direction column
    gap 16px
    padding 24px 16px 12px
    .partner-info-wrapper
      display flex
      flex-direction column
      border-radius 12px
      border 1px solid $gray1
      .partner-info-header
        display flex
        flex-direction column
        background-color $gray3
        padding 20px 24px
        border-radius 12px 0
      .partner-info-body
        display flex
        flex-direction column
        gap 8px
        padding 24px
        border-radius 0 12px
        .partner-info-body-item
          display grid
          grid-template-columns 20px 1fr
          gap 8px
          align-items center
      .partner-info-body-info
        white-space pre-line
  .tab-partner-info-container
    display flex
    flex-direction column
    gap 24px
    padding 24px 16px
    .tab-partner-info-wrapper
      display flex
      flex-direction column
    .partner-info-body
      display flex
      flex-direction column
      gap 8px
      .partner-info-body-item
        display grid
        grid-template-columns 20px 1fr
        gap 8px
        align-items center
    .partner-info-body-info
      white-space pre-line
  .box-right
    border 1px solid $gray2
    padding 12px
    border-radius 6px

  .img-partner
    width 32px
    height 32px
    border-radius 100%

  .themes-wrapper-mobile
    border-top 1px solid $gray2
    padding 24px 16px 16px
    margin-top 16px



.card-label
  color white
  font-style italic
  font-size 12px
  font-weight 600
  border-radius 2px
  height 22px
  display flex
  align-items center
  justify-content center
.beta
  background-color #7B61FF
  padding 1px 7px 1px 6px
.version
  background linear-gradient(322deg, #F60 22.51%, #F43B00 100%)
  padding 1px 8px 1px 6px
.lp-divider-gray3
  border-bottom 1px solid $gray3
.lp-divider-gray3-12
  border-bottom 12px solid $gray3

.button-group-preview
  display flex
  margin-top 20px
  gap 12px

  a
  .button
    width 100%
    flex 1
    display flex
    align-items center

  img
    filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)

.border-top-gray-3
  width 100%
  border-top 1px solid $gray3
  margin 12px 0

.tag-black
  background-color #000000
  color #ffffff
  padding 2px 6px
  position relative
  margin-left 8px
  font-size 12px
  .triangle
    width 6px
    height 6px
    top calc(50% - 3px)
    left -3px
    transform matrix(0.71, 0.65, -0.77, 0.71, 0, 0)
    background-color #000
    position absolute

.fixed-bottom-btn-container
    position fixed
    bottom 0
    left 50%
    transform translateX(-50%)
    width 100%
    z-index 100
    display flex
    align-items center
    justify-content center
    padding 32px 0
.bottom-btn-container
  display flex
  align-items center
  justify-content center
  width 100%

.fixed-bottom-btn-wrapper
  width 1000px
  display flex
  align-items center
  justify-content center
  gap 40px
  padding 16px 10px
  background-color white
  border-radius 8px
  box-shadow 0px 0px 10px 0px rgba(0, 0, 0, 0.15)

.bottom-btn-wrapper
  width 1000px
  display flex
  align-items center
  justify-content center
  gap 40px
  padding 16px 10px
  background-color white
  border-radius 8px
  box-shadow 0px 0px 10px 0px rgba(0, 0, 0, 0.15)

.bottom-fixed-btn
  width fit-content
  height 52px
  border-radius 50px
  background-color $primary
  font-weight 700
  color white
  display flex
  align-items center
  justify-content center
  padding 15px 40px

.grid-product-wrap
    display grid
    width 100%
    grid-template-columns repeat(4, 1fr)
    gap 24px

.themes-wrapper-mobile
  border-top 1px solid $gray2
  padding 24px 16px 16px
  margin-top 16px

.section
  padding-bottom 50px

.fade-enter-active, .fade-leave-active
  transition opacity 0.3s

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
  opacity 0

.line
  width 100%
  height 1px
  background-color $gray2
.line-bar
  width 1px
  height 16px
  background-color $gray1

@media (max-width: 640px)
  .container-top
    padding-top 60px !important
    padding-bottom 140px !important
</style>

<style lang="stylus">
@import '~assets/css/lp_main'

@media (max-width: 1024px)
  .tablet-preview
    display block

  .mobile-preview
    display none

@media (max-width: 640px)
  .tablet-preview
    display none

  .mobile-preview
    display block
</style>
