<template>
  <div class="tab-anchor-container">
    <a :id="anchor" ref="anchor" class="position-relative" :style="`top:${anchorTop}`"></a>
    <div :class="`tab-header${isMobile ? '-mobile' : ''}`" :style="{ top: headerHeight + 'px' }">
      <div
        class="tab-head unselect"
        v-for="item in menu"
        :key="'tab-head-' + item.id"
        :class="{ 'tab-head-selected': selectedItem === item.id }"
        @click="handleTabClick(item.id)">
        <div class="flex-center" style="gap: 4px">
          <div>{{ item.name }}</div>
          <div class="tab-head-count" v-if="item.name === '후기'">{{ reviewCount }}</div>
          <div class="tab-head-count" v-if="item.name === '문의'">{{ inquiryCount }}</div>
        </div>
      </div>
    </div>
    <div :id="`tab${isMobile ? '-mobile' : ''}${item.id}`" v-for="item in menu" :key="'tab' + item.id">
      <slot :name="`tab${isMobile ? '-mobile-' : ''}${item.id}`"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabAnchor',
  props: {
    menu: {
      type: Array,
      required: true,
    },
    reviewCount: {
      type: Number,
      default: 0,
    },
    inquiryCount: {
      type: Number,
      default: 0,
    },
    anchor: {
      type: String,
      default: 'tabs',
    },
    anchorTop: {
      type: String,
    },
    forceHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedItem: null,
      initialRender: true,
      isScrollingToTab: false,
      scrollLockTimer: null,
      headerHeight: 0,
      tabHeaderHeight: 0,
      tabPositions: [],
      tabHeights: [],
      lastScrollPosition: 0,
      scrollDirection: 'down',
      scrollDebounce: null,
      measurementAttempts: 0,
      maxMeasurementAttempts: 5,
      intersectionObserver: null,
      maxRetries: 10,
      retryCount: 0,
    };
  },
  created() {
    this.selectedItem = this.menu[0].id;
  },
  mounted() {
    this.$nextTick(() => {
      this.initMeasurements();
      this.addEventListeners();
      this.handleScroll();
      this.initialRender = false;
      this.setupIntersectionObserver();
      this.forceMeasurement();
    });
  },
  beforeDestroy() {
    this.removeEventListeners();
    clearTimeout(this.scrollLockTimer);
    cancelAnimationFrame(this.scrollThrottle);
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  },
  methods: {
    addEventListeners() {
      window.addEventListener('resize', this.handleResize);
      window.addEventListener('orientationchange', this.handleOrientationChange);
      if (this.isMobile) {
        window.addEventListener('scroll', this.handleScrollThrottle, { passive: true });
      } else {
        window.addEventListener('scroll', this.handleScrollThrottle);
      }
    },

    removeEventListeners() {
      window.removeEventListener('resize', this.handleResize);
      window.removeEventListener('orientationchange', this.handleOrientationChange);
      window.removeEventListener('scroll', this.handleScrollThrottle);
    },

    handleOrientationChange() {
      setTimeout(() => {
        this.initMeasurements();
        this.handleScroll();
      }, 100);
    },

    handleResize() {
      this.checkMobile();
      this.initMeasurements();
      this.handleScroll();
    },

    initMeasurements() {
      this.calculateHeaderHeight();
      this.calculateTabHeaderHeight();
      this.calculateTabPositionsAndHeights();

      if (this.tabPositions.every((pos) => pos === 0) && this.measurementAttempts < this.maxMeasurementAttempts) {
        this.measurementAttempts++;
        setTimeout(() => {
          this.initMeasurements();
        }, 200);
      } else {
        this.measurementAttempts = 0;
        if (this.tabPositions.every((pos) => pos === 0)) {
          console.warn('Failed to measure tab positions and heights after multiple attempts');
        }
      }
    },

    calculateHeaderHeight() {
      const header = document.querySelector(`${this.isMobile ? '.header-mobile' : '.header-pc'} .header-container`);
      this.headerHeight = header ? header.offsetHeight : 0;
    },

    calculateTabHeaderHeight() {
      const tabHeader = this.$el.querySelector(`.tab-header${this.isMobile ? '-mobile' : ''}`);
      this.tabHeaderHeight = tabHeader ? tabHeader.offsetHeight : 0;
    },

    calculateTabPositionsAndHeights() {
      this.tabPositions = [];
      this.tabHeights = [];
      this.menu.forEach((item) => {
        const elementId = `tab${this.isMobile ? '-mobile' : ''}${item.id}`;
        const element = document.getElementById(elementId);
        if (element) {
          // 강제 레이아웃 재계산
          void element.offsetHeight;

          const rect = element.getBoundingClientRect();
          const height = Math.max(rect.height, this.forceHeight);
          this.tabPositions.push(rect.top + window.pageYOffset);
          this.tabHeights.push(height);

          // 높이가 0인 경우 강제로 높이 설정
          if (height === 0) {
            element.style.minHeight = `${this.forceHeight}px`;
          }
        } else {
          this.tabPositions.push(0);
          this.tabHeights.push(this.forceHeight);
        }
      });

      // 모든 탭의 높이가 0인 경우 재시도
      if (this.tabHeights.every((height) => height === 0)) {
        this.forceMeasurement();
      }
    },

    setupIntersectionObserver() {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.forceMeasurement();
            }
          });
        },
        { threshold: 0.1 }
      );

      this.menu.forEach((item) => {
        const elementId = `tab${this.isMobile ? '-mobile' : ''}${item.id}`;
        const element = document.getElementById(elementId);
        if (element) {
          this.intersectionObserver.observe(element);
        }
      });
    },

    forceMeasurement() {
      if (this.retryCount >= this.maxRetries) {
        console.warn('Max retries reached for measurement');
        return;
      }

      this.retryCount++;

      setTimeout(() => {
        this.$nextTick(() => {
          this.calculateTabPositionsAndHeights();
          if (this.tabHeights.every((height) => height === 0)) {
            this.forceMeasurement();
          } else {
            this.retryCount = 0;
          }
        });
      }, 100 * this.retryCount); // 지연 시간을 점진적으로 증가
    },

    handleScrollThrottle() {
      if (!this.scrollThrottle) {
        this.scrollThrottle = requestAnimationFrame(() => {
          this.handleScroll();
          this.scrollThrottle = null;
        });
      }
    },

    handleScroll() {
      if (this.initialRender || this.isScrollingToTab) return;

      const currentScrollPosition = window.pageYOffset;
      this.scrollDirection = currentScrollPosition > this.lastScrollPosition ? 'down' : 'up';
      this.lastScrollPosition = currentScrollPosition;

      const scrollPosition = currentScrollPosition + this.headerHeight + this.tabHeaderHeight;
      let newSelectedItem = this.selectedItem;

      for (let i = 0; i < this.tabPositions.length; i++) {
        const tabTop = this.tabPositions[i] - (this.headerHeight + this.tabHeaderHeight);
        const tabBottom =
          i < this.tabPositions.length - 1
            ? this.tabPositions[i + 1] - (this.headerHeight + this.tabHeaderHeight)
            : Infinity;

        if (scrollPosition >= tabTop && scrollPosition < tabBottom) {
          newSelectedItem = this.menu[i].id;
          break;
        }
      }

      if (this.selectedItem !== newSelectedItem) {
        this.selectedItem = newSelectedItem;
      }
    },

    scrollToSelectedTab() {
      const selectedTabElement = document.getElementById(`tab${this.isMobile ? '-mobile' : ''}${this.selectedItem}`);
      if (selectedTabElement) {
        const elementPosition = selectedTabElement.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - (this.headerHeight + this.tabHeaderHeight);

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    },

    handleTabClick(itemId) {
      this.selectedItem = itemId;
      this.isScrollingToTab = true;
      this.scrollToSelectedTab();

      clearTimeout(this.scrollLockTimer);
      this.scrollLockTimer = setTimeout(() => {
        this.isScrollingToTab = false;
      }, 1000);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.tab-anchor-container
  position: relative
  min-height: 100px

.tab-header
  position: sticky
  top: 0
  z-index: 101
  background-color: white
  display: flex
  align-items: center

.tab-header-mobile
  position: sticky
  top: 56px // 모바일 헤더 높이에 맞게 조정
  left: 0
  right: 0
  z-index: 101
  background-color: white
  display: flex
  align-items: center
  border-bottom: 1px solid $gray2

@media (max-width: 768px)
  .tab-header
    top: 0

.tab-head
  background-color $gray4
  padding 12px
  border-bottom 1px solid $gray1
  color $sub3
  font-size 16px
  font-weight 500
  flex 1
  text-align center
.tab-head-selected
  color $primary
  border 1px solid $gray1
  border-bottom 0
  background-color white

@media (max-width: 500px)
  .tab-head
    background-color: white
    padding: 12px 0
    border-bottom: none
    font-size: 14px
    font-weight: 500

  .tab-head-selected
    border: none
    color: $primary
    font-weight: 700
    position: relative

    &::after
      content: ''
      position: absolute
      bottom: 0
      left: 0
      right: 0
      height: 2px
      background-color: $primary

[id^="tab"], [id^="tab-mobile"]
  min-height: 50px
  height: auto !important
  overflow: visible !important
  padding: 10px 0
</style>
